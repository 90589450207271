import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "auth",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["authenticated"]) {
        return next({
          name: "CustomersUsers",
        });
      }
      next();
    },
  },
  {
    path: "/mandoob_users",
    name: "MandoobUsers",
    component: () => import(/* webpackChunkName: "mandoob_users" */ "../views/Users/Mandoob.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/stores",
    name: "Store",
    component: () => import(/* webpackChunkName: "stores" */ "../views/Stores.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import(/* webpackChunkName: "orders" */ "../views/Orders.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
